<template>
  <div class="swipper">
    <div class="carousel">
      <el-carousel height="28.57vw" indicator-position="none">
        <el-carousel-item v-for="(item, index) in bannerData" :key="index">
          <div class="banner-item">
            <img class="banner-image" :src="item" alt="">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="count">
        <div class="count-item  scroll-item animate__rotateIn" data-wow-duration="2s" v-for="(item, index) in countData" :key="index">
          <div class="count-icon">
            <img class="icon-img" :src="item.icon" alt="">
          </div>
          <div class="count-content">
            <div class="count-number">{{ item.count }}</div>
            <div class="count-name">{{ item.name }}</div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>

import banner1 from '@/assets/image/banner/banner1-1.jpg'
import banner2 from '@/assets/image/banner/banner2-2.jpg'
import banner3 from '@/assets/image/banner/banner3-3.jpg'
import banner4 from '@/assets/image/banner/banner4-4.jpg'
import icon1 from '@/assets/image/about/kehushu.png'
import icon2 from '@/assets/image/about/shangjiashu.png'
import icon3 from '@/assets/image/about/jiaoyijine.png'
export default {
  name: "banner-componnet",
  data() {
    return {
      bannerData: [banner1, banner2, banner3, banner4],
      countData: [
        {
          icon: icon1,
          count: '30,000+',
          name: '客户数'
        },
        {
          icon: icon2,
          count: '60,000+',
          name: '商家数'
        },
        {
          icon: icon3,
          count: '19,280w+',
          name: '交易金额'
        },
      ]
    }
  },
  methods: {
    onSwiper(swiper) {
      console.log('swiper: ', swiper);

    },
    onSlideChange() {
      console.log('onSlideChange: ');

    }

  }
};
</script>
<style scoped lang="less">
.swipper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .carousel {
    width: 100vw;
  }
  .count {
    // position: relative;
    z-index: 8;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;

    width: 100vw;
    .count-item {
      background: #fff;
      // box-shadow: 0 0 12px rgba(51, 51, 51, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      border-left: 1px solid #eee;
      .count-icon {
        height: 26px;
        margin-right: 20px;
        img {
          height: 100%;
        }
      }
      .count-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .count-number {
          font-weight: 700;
          color: #F57711;
          font-size: 16px;
          
        }
        .count-name {
          font-size: 14px;
          font-weight: 550;
        }
      }
    }
  }
}
.banner-item {
  .banner-image {
    width: 100%;
    height: 28.56vw;
  }
}
</style>
