<template>
  <div class="platform">
    <div class="title">{{ title }}</div>
    <div class="desc">{{ desc }}</div>
    <div class="list">
            <div
              class="item scroll-item animate__flipInX"
              data-wow-duration="2s"
              :style="{ animationDelay: `${Math.random()}s` }"
              v-for="(item, index) in data"
              :key="index"
            >
              <div class="icon" :style="{width: item.width}">
                <img :src="item.icon" alt="" />
              </div>
            </div>
    </div>
  </div>
</template>
  
  <script>
import icon1 from "@/assets/image/logo/qunaer.png";
import icon2 from "@/assets/image/logo/kuaishou.png";
import icon3 from "@/assets/image/logo/douyin.png";
import icon4 from "@/assets/image/logo/element.png";
import icon5 from "@/assets/image/logo/weixin.png";
import icon6 from "@/assets/image/logo/dangdang.png";
import icon7 from "@/assets/image/logo/shunfeng.png";
import icon8 from "@/assets/image/logo/tianmao.png";
export default {
  name: "platform-component",
  data() {
    return {
      title: "已接入平台",
      desc: "已成功对接各大平台，多平台多店铺无缝对接",
      data: [
        { icon: icon1, width: "160px" },
        { icon: icon2, width: "100px" },
        { icon: icon3, width: "100px" },
        { icon: icon4, width: "120px" },
        { icon: icon5, width: "100px" },
        { icon: icon6, width: "110px" },
        { icon: icon7, width: "120px" },
        { icon: icon8, width: "120px" },
      ],
    };
  },
};
</script>
  
  <style scoped lang="less">
.platform {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 45px;
    margin-bottom: 10px;
    width: 1245px;
    text-align: center;
  }
  .desc {
    text-align: center;
    font-size: 16px;
    line-height: 50px;
  }
  .list {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item {
      font-size: 20px;
      width:163px;
      height: 100px;
      margin: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 5px 9px 1px rgba(0,8,79,0.1);
      .icon {
        img {
            width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>